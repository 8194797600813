<div class="bmt-alerts" *ngIf="bmtAlerts.length; else noAlertsOrErrors;">
  <div class="alert-box" *ngFor="let alert of bmtAlerts;let index=index;">
    <div class="marker-icon" [ngClass]="alert?.statusType?.code!='ACTIVE'?'read':''"></div>
    <div class="row">
      <div class="col-xs-1" [ngSwitch]="alert?.priorityType?.code">
        <ng-container *ngSwitchCase="'HIGH'">
          <p class="priority high-priority">
            {{alert?.priorityType?.code}}
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'MEDIUM'">
          <p class="priority medium-priority">
            {{alert?.priorityType?.code}}
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'LOW'">
          <p class="priority low-priority">
            {{alert?.priorityType?.code}}
          </p>
        </ng-container>

        <!-- <div class="dismiss-btn">
            DISMISS
          </div> -->
      </div>
      <div class="col-xs-10" style="padding: 0px 0px 0px 40px;">
        <div class="row">
          <div class="col-xs-6">
            Subject:
            <span class="bold">{{alert?.subject}}</span>
          </div>
          <div class="col-xs-6">
            <div class="row">
              <div class="col-xs-6">
                Send By:
                <span class="bold">{{alert?.createdBy}}</span>
              </div>
              <div class="col-xs-6">
                Administrator:
                <span class="bold">{{alert?.adminPlanAcronym}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6">
                Created on:
                <span class="bold">{{alert?.createdOn | date:'MM/dd/yyyy'}}</span>
              </div>
              <div class="col-xs-6">
                Last Updated:
                <span class="bold">{{alert?.updatedOn | date:'MM/dd/yyyy'}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 bold">
            <div class="horizontal-ruler"></div>
            <div class="message-section">
              {{alert?.message}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noAlertsOrErrors>
    <p [class.error]="bmtAlertErrors" class="noAlerts">{{bmtAlertErrors || 'No alerts'}}</p> 
</ng-template>
