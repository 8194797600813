<div class="custom-group-comp">
  <bmt-client-search [selectedAdminPlan]="selectedAdminPlan"
                     (clientSelected)="clientSelected($event)"
                     (subClientSelected)="subClientSelected($event)"
                     (selectedAllSubClients)="subClientSelected($event)"
                     (resetSelected)="resetClientSelected($event)"
                     [allSubClientsSelectFeature]="true"></bmt-client-search>
  <div class="custom-group-inputs" *ngIf="(selectedSubClients && selectedSubClients.length > 0) || allSubClientFlow">
    <form [formGroup]="customGroupForm" (ngSubmit)="onCustomGroupSubmitted(0)">
      <rs-input formControlName="fromDate" type="date" placeholder="MM/DD/YYYY" label="From Date" validation="eager"></rs-input>
      <rs-input formControlName="toDate" type="date" placeholder="MM/DD/YYYY" label="To Date" validation="eager"></rs-input>
      <rs-select formControlName="frequency" [items]="frequencyDropDown" label="Frequency" validation="eager"></rs-select>
      <button [disabled]="isFetching || !customGroupForm.valid" rs-raised-button color="primary">Submit</button>
    </form>
  </div>
  <rs-loader *ngIf="isFetching"></rs-loader>
  <div class="custom-group-error" *ngIf="customGroupError">{{customGroupError}}</div>
  <ng-container *ngIf="showResults">
    <table *ngIf="!isFetching">
      <thead>
      <tr>
        <th>Group - subgroup</th>
        <th>Frequency</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Run Date</th>
        <th>View Reports</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="customGroupReports && customGroupReports.pagination && customGroupReports.pagination.totalRecords">
        <tr *ngFor="let report of customGroupReports.reports">
          <td>{{report.clientSubClientId[0].clientSpecifiedId}} - <collapsable-column [content]="report.clientSubClientId[0].subClientSpecifiedIds.split(',')" [size]="2"></collapsable-column></td>
          <td>{{report.frequency}}</td>
          <td>{{report.startDate | date: 'MM/dd/yyyy'}}</td>
          <td>{{report.endDate | date: 'MM/dd/yyyy'}}</td>
          <td>{{report.date | date: 'MM/dd/yyyy'}}</td>
          <td>{{report.name}} <a (click)="selectedReport.emit({id: report.id, type: report.format})">{{'[' + report.format + ']'}}</a></td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <rs-paginator showFirstLastButtons [length]="customGroupReports.pagination.totalRecords"
                  (page)="pageChanged($event)" [pageSize]="defaultPageSize"></rs-paginator>
  </ng-container>
</div>
