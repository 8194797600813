<table>
  <thead>
  <tr>
    <th>Member ID</th>
    <th>Date</th>
    <th>Change Type</th>
    <th>Result</th>
    <th>Transaction ID</th>
    <th>UserID</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="transactions.length; else noResults">
    <tr *ngFor="let transaction of transactions">
      <td>
        <ng-container *ngIf="transaction.transactionStatus.toUpperCase() === 'SUCCESS'; else unSelect">
          <a (click)="selectedTransaction.emit(transaction)">{{transaction.memberId || ''}}</a></ng-container>
        <ng-template #unSelect>{{transaction.memberId || ''}}</ng-template>
      </td>
      <td>{{transaction.transactionTime | date: 'MM/dd/yyyy HH:mm'}}</td>
      <td>{{transaction.transactionType}}</td>
      <td>{{transaction.transactionStatus | uppercase}}</td>
      <td>{{transaction.transactionId}}</td>
      <td>{{transaction.userName}}</td>
    </tr>
  </ng-container>
  <ng-template #noResults>
    <tr>
      <td colspan="6" class="no-results"><label>No Transactions Found</label></td>
    </tr>
  </ng-template>
  </tbody>
</table>

