<div class="summary-details-component">
  <div><a class="backToTransactions" (click)="backSearch.emit(true)">Back To Results</a></div>
  <!--  Eligibility history -->
  <table>
    <thead>
    <tr>
      <th>Eligibility Status</th>
      <th>Effective Date</th>
      <th>Received Date</th>
      <th>Statues Reason</th>
      <th>Waiting Period Exempt</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="memberEligHistory.length; else noResults">
      <tr [class.highlight]="(history.transaction.date | date: 'MM/dd/yyyy HH:mm') == (transaction.transactionTime | date: 'MM/dd/yyyy HH:mm')" *ngFor="let history of memberEligHistory">
        <td>{{history.eligibility.eligStatusType.value}}</td>
        <td>{{history.eligibility.effectiveDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{history.eligibility.receivedDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{history.eligibility.eligStatusReasonType.value}}</td>
        <td>{{history.eligibility.isWaitExempt ? 'Yes' : 'No'}}</td>
        <td>{{history.transaction.auditActionType.description}}</td>
        <td>{{history.transaction.date | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{history.transaction.userId}}</td>
        <td>{{history.transaction.applicationName}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <!--  COB Type History -->
  <table>
    <thead>
    <tr>
      <th>COB Type</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="cobHistory.length; else noResults">
      <tr [class.highlight]="(cob.transaction.date | date: 'MM/dd/yyyy HH:mm') == (transaction.transactionTime | date: 'MM/dd/yyyy HH:mm')" *ngFor="let cob of cobHistory">
        <td>{{cob.coordinationOfBenefit.paymentOrderType.value}}</td>
        <td>{{cob.coordinationOfBenefit.startDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{cob.coordinationOfBenefit.endDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{cob.transaction.auditActionType.value}}</td>
        <td>{{cob.transaction.date | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{cob.transaction.userId}}</td>
        <td>{{'BMT'}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <!--  Coverage History -->
  <table>
    <thead>
    <tr>
      <th>Coverage Type</th>
      <th>Effective Date</th>
      <th>Received Date</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="coverageHistory.length; else noResults">
      <tr [class.highlight]="(coverage.transaction.date | date: 'MM/dd/yyyy HH:mm') == (transaction.transactionTime | date: 'MM/dd/yyyy HH:mm')" *ngFor="let coverage of coverageHistory">
        <td>{{coverage.coverage.coverageType.static_identifier}}</td>
        <td>{{coverage.coverage.effectiveDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{coverage.coverage.receivedDate | date: 'MM/dd/yyyy'}}</td>
        <td>{{coverage.transaction.auditActionType.value}}</td>
        <td>{{coverage.transaction.date | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{coverage.transaction.userId}}</td>
        <td>{{coverage.transaction.applicationName}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <!--  Person Identifier History -->
  <table>
    <thead>
    <tr>
      <th>Person Identifier Type</th>
      <th>Value</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="personHistory.length > 100; else noResults">
      <tr *ngFor="let transaction of []">
        <td>{{transaction}}</td>
        <td>{{transaction}}</td>
        <td>{{transaction}}</td>
        <td>{{transaction}}</td>
        <td>{{transaction}}</td>
        <td>{{transaction}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <!--  Person History -->
  <table>
    <thead>
    <tr>
      <th>Prefix</th>
      <th>FirstName</th>
      <th>LastName</th>
      <th>Suffix</th>
      <th>DOB</th>
      <th>Gender</th>
      <th>Single Name Indicator</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="personHistory.length; else noResults">
      <tr [class.highlight]="(person.transaction.date | date: 'MM/dd/yyyy HH:mm') == (transaction.transactionTime | date: 'MM/dd/yyyy HH:mm')" *ngFor="let person of personHistory">
        <td>{{person.personHistory.person.prefix}}</td>
        <td>{{person.personHistory.person.firstName}}</td>
        <td>{{person.personHistory.person.lastName}}</td>
        <td>{{person.personHistory.person.suffix}}</td>
        <td>{{person.personHistory.person.dateOfBirth | date: 'MM/dd/yyyy'}}</td>
        <td>{{person.personHistory.person.gender}}</td>
        <td>{{person.personHistory.person.singleNameInd}}</td>
        <td>{{person.transaction.auditActionType.value || ''}}</td>
        <td>{{person.transaction.date | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{person.transaction.userId || ''}}</td>
        <td>{{person.transaction.applicationName || ''}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <!--  Person Address History -->
  <table>
    <thead>
    <tr>
      <th>Address Line1</th>
      <th>Address Line2</th>
      <th>County</th>
      <th>City</th>
      <th>State</th>
      <th>Zip</th>
      <th>Country</th>
      <th>Transaction Type</th>
      <th>Transaction Date</th>
      <th>UserID</th>
      <th>Application Name</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="addressHistory.length; else noResults">
      <tr [class.highlight]="(address.transaction.date | date: 'MM/dd/yyyy HH:mm') == (transaction.transactionTime | date: 'MM/dd/yyyy HH:mm')" *ngFor="let address of addressHistory">
        <td>{{address.address.addressLine1}}</td>
        <td>{{address.address.addressLine2}}</td>
        <td>{{address.address.county}}</td>
        <td>{{address.address.city}}</td>
        <td>{{address.address.state}}</td>
        <td>{{address.address.zip}}</td>
        <td>{{address.address.country}}</td>
        <td>{{address.transaction.auditActionType.value}}</td>
        <td>{{address.transaction.date | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{address.transaction.userId}}</td>
        <td>{{address.transaction.applicationName}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-template #noResults>
    <tr>
      <td colspan="12" class="no-results"><label>No History Found</label></td>
    </tr>
  </ng-template>
</div>
