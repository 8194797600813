<div class="sticky" [class.prd-sticky]="region === 'prod'">
  <div class="floating-banner">
    <div><label routerLink="/eligibility/add-member">Manage Eligibility</label> / <label>Add Member</label></div>
    <h4>Add New Member</h4>
  </div>
</div>
<div class="add-member-section">
  <!-- Client Plan Details Component -->
  <client-plan-details (clientInfo)="clientDetails = $event" [feed]="clientSelected"
                       [features]="['client', 'subClient', 'contract','dialogs']"></client-plan-details>
  <div>
    <elig-add-member [key]="'BMT'" [clientDetails]="clientDetails" [selectedAdminPlan]="selectedAdminPlan" [feed]="addMemberFeed"
                     (wasAddDone)="wasAddDone($event)"></elig-add-member>
  </div>
</div>
