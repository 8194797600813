<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Dentist Directory</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col"></div>
    </div>
    <div class="row search-comp">
      <div class="card col-sm-12">
        <dentist-directory [selectedAdminPlan]="selectedAdminPlan"></dentist-directory>
      </div>
    </div>
  </div>
</div>
