<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">{{userInfo.selectedAction === 'authorizations' ? 'User Authorizations' : 'Manage User'}}</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <!-- <ul class="list-inline search-nav-list">
          <li><a (click)="toggle(true)" [class.search-nav-active]='session'>Session</a></li>
          <li><a (click)="toggle(false)" [class.search-nav-active]='!session'>Previous Transactions</a></li>
        </ul> -->
      </div>
    </div>
  </div>
</div>
<div class="row manage-user-or-auth-container">
    <div class="col-sm-12 card">
        <bmt-view-auth *ngIf="pageToShow === 'authorizations'" [userInfo]="userInfo" (getClients)="getClients($event)"></bmt-view-auth>
    </div>
</div>