<div class="row pg-style bmt-group-admin">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">User Admin</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
          <li><a routerLink="/userAdmin/create" [class.search-nav-active]="page === 'create'">Create User</a></li>
          <li><a routerLink="/userAdmin/manage" [class.search-nav-active]="page === 'manage'">Manage User</a></li>
        </ul>
      </div>
    </div>
    <div class="row search-comp">
        <div class="card col-sm-12">
            <bmt-create-user *ngIf="page === 'create'"></bmt-create-user>
            <bmt-manage-user *ngIf="page === 'manage'"></bmt-manage-user>
        </div>
    </div>
  </div>
</div>

