export const registrationInitialState: IRegistrationState = {
  isFetching: false,
  administrators: {
    userIdentifier: '',
    password: '',
    confirmPassword: '',
    securityQuestion1: '',
    securityAnswer1: '',
    securityQuestion2: '',
    securityAnswer2: '',
    securityQuestion3: '',
    securityAnswer3: '',
    validationToken: ''
  }
};

export interface IRegistrationState {
  isFetching: boolean;
  administrators: IAdministrators;
}

export interface IAdministrators {
  userIdentifier: string;
  password: string;
  confirmPassword: string;
  securityQuestion1: string;
  securityAnswer1: string;
  securityQuestion2: string;
  securityAnswer2: string;
  securityQuestion3: string;
  securityAnswer3: string;
  validationToken: string;
}
