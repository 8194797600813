import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './login/login.module';
import { LoginComponent } from './login/login.component';
import { OidcCallbackComponent } from './oidc-callback/oidc-callback.component';
import { LogoutComponent } from './logout/logout.component';
import { ManageEligibilityContainer } from './manage-eligibility/manage-eligibility.container';
import { ManageEligibilityModule } from './manage-eligibility/manage-eligibility.module';
import { MemberDetailsModule } from './member-details/member-details.module';
import { MemberDetailsContainer } from './member-details/member-details.container';
import { BenefitsInquiryModule } from './benefits-inquiry/benefits-inquiry.module';
import { BenefitsInquiryComponent } from './benefits-inquiry/benefits-inquiry.component';
import { ReportsModule } from './reports/reports.module';
import { ReportsContainer } from './reports/reports.container';
import { AddMemberModule } from './add-member/add-member.module';
import { BillingModule } from './billing/billing.module';
import { AddMemberContainer } from './add-member/add-member.container';
import { SummaryOfChangesModule } from './summary-of-changes/summary-of-changes.module';
import { SummaryOfChangesContainer } from './summary-of-changes/summary-of-changes.container';
import { RegistrationModule } from './registration/registration.module';
import { RegistrationComponent } from './registration/registration.component';
import { DentistDirectoryComponent } from './dentist-directory/dentist-directory.component';
import { DentistDirectoryModule } from './dentist-directory/dentist-directory.module';
import { BillingContainer } from './billing/billing.container';
import { UserAdminModule } from './user-admin/user-admin.module';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { ManageUserOrAuthComponent } from './manage-user-or-auth/manage-user-or-auth.component';
import { ManageUserOrAuthModule } from './manage-user-or-auth/manage-user-or-auth.module';
import { NotAuthorizedComponent } from 'src/app/not-authorized/not-authorized.component';
import { ProfileModule } from './profile/profile.module';
import { ProfileComponent } from './profile/profile.component';
import { ClientKnowledgeContainer } from './clientknowledge/clientknowledge.container';
import { ClientknowledgeModule } from './clientknowledge/clientknowledge.module';
import { HelpComponent } from 'src/app/help/help.component';
import { FamilyEnrollmentModule } from './family-enrollment/family-enrollment.module';
import { FamilyEnrollmentContainer } from './family-enrollment/family-enrollment.container';
import { AuthGuard } from 'src/app/authentication/auth.guard';

const routes: Routes = [
  { path: '.html', redirectTo: '' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '404', pathMatch: 'full', component: PageNotFoundComponent },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'logout', pathMatch: 'full', component: LogoutComponent },
  { path: 'oidc-callback', pathMatch: 'full', component: OidcCallbackComponent },
  { path: 'eligibility/:id', canActivate: [AuthGuard], component: ManageEligibilityContainer, pathMatch: 'full' },
  { path: 'details', canActivate: [AuthGuard], component: MemberDetailsContainer, pathMatch: 'full' },
  { path: 'benefits', canActivate: [AuthGuard], component: BenefitsInquiryComponent, pathMatch: 'full' },
  { path: 'reports', canActivate: [AuthGuard], component: ReportsContainer, pathMatch: 'full' },
  { path: 'dentist', canActivate: [AuthGuard], component: DentistDirectoryComponent, pathMatch: 'full' },
  { path: 'add-member', canActivate: [AuthGuard], component: AddMemberContainer, pathMatch: 'full' },
  { path: 'summary-of-changes', canActivate: [AuthGuard], component: SummaryOfChangesContainer, pathMatch: 'full' },
  {
    path: 'sat',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    loadChildren: () => import('./sat/sat.module').then(m => m.SatModule)
  },
  { path: 'registration', component: RegistrationComponent },
  { path: 'billing', canActivate: [AuthGuard], component: BillingContainer, pathMatch: 'full' },
  { path: 'userAdmin/:id', component: UserAdminComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'manage-user', component: ManageUserOrAuthComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  { path: 'notAuthorized', pathMatch: 'full', component: NotAuthorizedComponent },
  { path: 'profile/:id', pathMatch: 'full', canActivate: [AuthGuard], component: ProfileComponent },
  { path: 'clientknowledge', canActivate: [AuthGuard], component: ClientKnowledgeContainer, pathMatch: 'full' },
  { path: 'help', canActivate: [AuthGuard], component: HelpComponent, pathMatch: 'full' },
  { path: 'family-enrollment', canActivate: [AuthGuard], component: FamilyEnrollmentContainer, pathMatch: 'full' },


  // { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    LoginModule,
    ManageEligibilityModule,
    MemberDetailsModule,
    BenefitsInquiryModule,
    ReportsModule,
    AddMemberModule,
    SummaryOfChangesModule,
    RegistrationModule,
    BillingModule,
    ClientknowledgeModule,
    DentistDirectoryModule,
    UserAdminModule,
    ManageUserOrAuthModule,
    ProfileModule,
    FamilyEnrollmentModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
