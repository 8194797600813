<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Reports</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
          <li *ngIf="reportFeatures.includes('overageDependentNotice')">
            <div class="dropdown">
              <a class="dropbtn" [class.search-nav-active]="subHeader !== 'customGroupReport'">Overage Dependent {{chosenReportAction}} </a><i class="arrow down"></i>
              <div class="dropdown-content">
                <a (mousedown)="changeForm('Notice')">Dependent Notice</a>
                <a (mousedown)="changeForm('Term Consolidated Report')">Term Consolidated Report</a>
                <a (mousedown)="changeForm('Adhoc Report')">Adhoc Report</a>
              </div>
            </div>
          </li>
          <li *ngIf="reportFeatures.includes('customGroupReport')"><a [class.search-nav-active]="subHeader === 'customGroupReport'" (click)="toggle('customGroupReport')">Custom Group Report</a></li>
        </ul>
      </div>
    </div>
    <div class="row report-component">
      <div class="col-sm-12 card">
        <app-overage-dependent *ngIf="subHeader !== 'customGroupReport'" [reportType]="chosenReportAction"></app-overage-dependent>
        <bmt-custom-group-report *ngIf="subHeader === 'customGroupReport'"></bmt-custom-group-report>
      </div>
    </div>
  </div>
</div>
