import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { Observable } from 'rxjs';
import { IAdministrators } from './state';

@Injectable({
  providedIn: 'root',
})
export class RegistrationApiService {
  constructor(private http: HttpClient, private config: AppConfig) {
  }


  getValidationToken(request): Observable<any> {
    return this.http.post<any>(this.config.validationUrl, { ...request });
  }

  getAdministrators(request: IAdministrators): Observable<any> {
    return this.http.post<any>(this.config.administratorsUrl, { ...request }, {observe: 'response'});
  }
}
