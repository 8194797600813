import { Component, OnInit } from '@angular/core';
import { MagnoliaService } from 'src/app/magnolia/magnolia.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bmt-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpContent: any;

  constructor(private magnoliaService: MagnoliaService,
              private sanitized: DomSanitizer) {
  }

  ngOnInit(): void {
    this.magnoliaService.getMagnoliaContent('help').subscribe((content) => {
      this.helpContent = this.sanitized.bypassSecurityTrustHtml(content);
    });
  }

}
