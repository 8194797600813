import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitsInquiryComponent } from './benefits-inquiry.component';
import { BenefitsModule as BLibModule } from '@roosevelt/benefits-ui-lib';
import { ClientSearchModule } from '@roosevelt/client-ui-lib';

@NgModule({
  declarations: [BenefitsInquiryComponent],
  imports: [
    CommonModule,
    BLibModule,
    ClientSearchModule
  ]
})
export class BenefitsInquiryModule { }
