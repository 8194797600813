<div class="credentials-component">
  <form [formGroup]="credentialsForm" #ngCredentialsForm>
    <div class="strong">Register Account <span>(Step 2 of 2)</span></div>
    <rs-input class="form-content" formControlName='userIdentifier' validation="eager" label="Username" type="text"></rs-input>
    <rs-input class="form-content" formControlName='password' label="Password" validation="eager" type="password"></rs-input>
    <rs-input class="form-content" formControlName='confirmPassword' label="Confirm Password" validation="eager" type="password"></rs-input>
<!--    <h4>Security Questions</h4>
    <rs-select formControlName='securityQuestion1' label="#1" validation="eager" [items]="securityQuestions" (change)="filterQuestions()"></rs-select>
    <rs-input class="form-content" formControlName='securityAnswer1' label="#1" validation="eager" type="text"></rs-input>
    <rs-select formControlName='securityQuestion2' label="#2" validation="eager" [items]="securityQuestions" (change)="filterQuestions()"></rs-select>
    <rs-input class="form-content" formControlName='securityAnswer2' label="#2" validation="eager" type="text"></rs-input>
    <rs-select formControlName='securityQuestion3' label="#3" validation="eager" [items]="securityQuestions" (change)="filterQuestions()"></rs-select>
    <rs-input class="form-content" formControlName='securityAnswer3' validation="eager" label="#3" type="text"></rs-input>-->
    <div class="credentials-button">
      <button style="float: right" rs-raised-button color="primary" type="submit" (click)="credentialsSubmit.emit(credentialsForm.value)" [disabled]="credentialsForm.invalid">Continue</button>
    </div>
  </form>
</div>
