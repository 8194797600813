<div class="manage-user-container">
    <h4 style="padding: 0px 10px;">Users List</h4>
    <rs-loader *ngIf="updatingUserInfo || fetchingUserInfo || isFetchingUserList"></rs-loader>
    <p *ngIf="fetchingUserErrors" class="error">{{fetchingUserErrors}}</p>
    <!-- <p *ngIf="updatingUserStatus !== 'user data updated' && errorsUpdatingUser" class="error">{{errorsUpdatingUser}}</p> -->
    <p *ngIf="!isFetchingUserList && !fetchingUserErrors && !users.length" class="error">No users were created by you</p>
    <ng-container *ngIf="users.length">
        <table>
            <thead>
                <th (click)="sortTheUserList('userName')">User Name
                    <span *ngIf="sortField === 'userName' && sortOrder === 'DESC'">&#8595;</span>
                    <span *ngIf="sortField === 'userName' && sortOrder === 'ASC'">&#8593;</span>
                </th>
                <!-- <th (click)="sortTheUserList('firstName')">First Name
                    <span *ngIf="sortField === 'firstName' && sortOrder === 'DESC'">&#8595;</span>
                    <span *ngIf="sortField === 'firstName' && sortOrder === 'ASC'">&#8593;</span>
                </th>
                <th (click)="sortTheUserList('lastName')">Last Name
                    <span *ngIf="sortField === 'lastName' && sortOrder === 'DESC'">&#8595;</span>
                    <span *ngIf="sortField === 'lastName' && sortOrder === 'ASC'">&#8593;</span>
                </th> -->
                <th (click)="sortTheUserList('userType')">User Type
                    <span *ngIf="sortField === 'userType' && sortOrder === 'DESC'">&#8595;</span>
                    <span *ngIf="sortField === 'userType' && sortOrder === 'ASC'">&#8593;</span>
                </th>
                <th></th>
                <th></th>
                <th (click)="sortTheUserList('userStatus')">User Status
                    <span *ngIf="sortField === 'userStatus' && sortOrder === 'DESC'">&#8595;</span>
                    <span *ngIf="sortField === 'userStatus' && sortOrder === 'ASC'">&#8593;</span>
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{user.userName}}</td>
                    <!-- <td>{{user.firstName || ''}}</td>
                    <td>{{user.lastName || ''}}</td> -->
                    <td>{{user.userType}}</td>
                    <td><a (click)="navigateByAction('manage', user)">Manage User</a></td>
                    <td><a (click)="navigateByAction('authorizations', user)">Authorizations</a></td>
                  <td>{{user.userStatus}}
                    <button class="enable-disable-user" (click)="enableDisableUser(user.userName, user.userStatus === 'Enabled')" rs-raised-button
                            [color]="user.userStatus === 'Enabled' ? 'secondary' : 'primary'">{{user.userStatus === 'Enabled' ? 'Disable' : 'Enable'}}</button>
                  </td>
                </tr>
            </tbody>
        </table>
        <rs-paginator showFirstLastButtons [length]="totalUsers"
                        (page)="getMoreUsers($event)"
                        [pageSize]="defaultPageSize"></rs-paginator>
    </ng-container>
</div>
<!-- Confirmation popup -->
<rs-dialog [visible]="manageUserInfo && manageUserInfo.userName" heading="Update User Info" maxWidth="498px" class="bmt-manage-user-info" (visibleChange)="resetUpdateUserForm()">
    <p *ngIf="errorsfetchingUserInfo" class="error">Error fetching user info: {{errorsfetchingUserInfo}}</p>
    <ng-container *ngIf="!updatingUserStatus">
        <div *ngIf="(!errorsfetchingUserInfo && !fetchingUserInfo)" style="padding: 20px;display: flex;justify-content: space-around;overflow-x: scroll;flex-direction: column;">
            <p *ngIf="errorsUpdatingUser" class="error">{{errorsUpdatingUser}}</p>
            <form [formGroup]="updateUserForm" class="bmt-create-user" (ngSubmit)="updateUserInfo()">
                <div style="width: 350px;">
                    <rs-input formControlName="userIdentifier" class="create-user-inputs" label="User Name:"></rs-input>
                    <rs-input validation="eager" formControlName="firstName" class="create-user-inputs" label="First Name:"></rs-input>
                    <rs-input validation="eager" formControlName="lastName" class="create-user-inputs" label="Last Name:"></rs-input>
                    <rs-input validation="eager" formControlName="companyName" class="create-user-inputs" label="Company Name:"></rs-input>
                    <rs-input validation="eager" type="email" formControlName="emailAddress" class="create-user-inputs" label="Email Address:"></rs-input>
                </div>
                <div style="padding-top: 20px;justify-content: space-between;display: flex;">
                    <button style="width: auto;" type="reset" rs-raised-button color="secondary" (click)="manageUserInfo = null">Cancel</button>
                    <button style="width: auto;" [disabled]="!updateUserForm.valid || !areThereAnyChanges" type="submit" rs-raised-button color="primary">Update</button>
                </div>
            </form>
        </div>
    </ng-container>
    <div class="status-update" *ngIf="updatingUserStatus">
        <p>User info has been updated</p>
        <button style="width: auto;" (click)="resetUpdateUserForm()" rs-raised-button color="secondary">close</button>
    </div>
</rs-dialog>
