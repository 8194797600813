<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <!-- SEARCH BANNER -->
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Benefits Inquiry</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
          <li><a class="search-nav-active">Group Benefits</a></li>
        </ul>
      </div>
    </div>
    <div class="row search-comp">
      <div class="card col-sm-12">
        <!-- <benefits-details [selectedAdminPlan]="selectedAdminPlan" (clientDetails)="getClientDetails($event)">
        </benefits-details> -->
        <bmt-client-search [selectedAdminPlan]="selectedAdminPlan" (clientSelected)="resetOrClientSelected($event)"
                           (resetSelected)="resetOrClientSelected($event)"
                           (subClientSelected)="getSubClientDetails($event)">
        </bmt-client-search>
        <ng-container *ngIf="selectedSubClients && selectedSubClients.length">
          <benefits-details [feed]="feed"></benefits-details>
        </ng-container>
      </div>
    </div>
  </div>
</div>
