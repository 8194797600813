import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITransactions } from '../../store/summaryOfChanges/state';

@Component({
  selector: 'app-user-transactions',
  templateUrl: './user-transactions.component.html',
  styleUrls: [ './user-transactions.component.scss' ]
})
export class UserTransactionsComponent implements OnInit {

  @Input() transactions: ITransactions[];
  @Output() selectedTransaction = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }
}
