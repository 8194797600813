<rs-loader *ngIf="fetchingAuths"></rs-loader>
<!-- userInfo.adminRole === 'BMT-GROUP-ADMIN' || userInfo.adminRole === 'BMT-SUBGROUP-ADMIN' -->
<!-- {{userInfo.adminRole}} -->
<div class="auth-at-group-level">
    <p *ngIf="errors" class="error">{{errors}}</p>
    <ng-container *ngIf="!errors && userAndAdminAuths.userRelatedAuthorizations && userAndAdminAuths.userRelatedAuthorizations.length && selectedUserId">
        <!-- <rs-pagination [(ngModel)]="currentPage" [totalItems]="totalAuths"
                        (pageChanged)="getMoreAuths($event)" [directionLinks]="true"
                        [itemsPerPage]="defaultPageSize" ></rs-pagination> -->
        <table>
            <thead>
                <th>Auth given</th>
                <th>Subgroup ID</th>
                <th>Subgroup Name</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel" >Eligibility Access</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">Client Knowledge</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">Custom Client Reports</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">Billing Report</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">Claim Detail Report</th>
                <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">Sat Access Level</th>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of userAndAdminAuths.userRelatedAuthorizations">
                    <tr>
                        <td>
                            <rs-checkbox [id]="user.subGroupSpecifiedIdentifier+'userPrivilegeSelected'" [checked]="user.userPrivilegeSelected" disabled></rs-checkbox>
                        </td>
                        <td>{{user.subGroupSpecifiedIdentifier}}</td>
                        <td>{{user.subGroupName}}</td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                            <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].eligibilityAccessLevel">
                                {{user.eligibilityAccessLevel || 'None'}}
                            </ng-container>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                            <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].clientKnowledgeIndicator">
                                <rs-checkbox [id]="user.subGroupSpecifiedIdentifier+'clientKnowledgeIndicator'" [checked]="user.clientKnowledgeIndicator" disabled></rs-checkbox>
                            </ng-container>
                        </td>
                      <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                        <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].customClientReportAccessIndicator">
                          <rs-checkbox [id]="user.subGroupSpecifiedIdentifier+'customClientReportAccessIndicator'" [checked]="user.customClientReportAccessIndicator" disabled></rs-checkbox>
                        </ng-container>
                      </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                            <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].billingReportAccessIndicator">
                                <rs-checkbox [id]="user.subGroupSpecifiedIdentifier+'billingReportAccessIndicator'" [checked]="user.billingReportAccessIndicator" disabled></rs-checkbox>
                            </ng-container>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                            <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].claimDetailReportAccessIndicator">
                                <rs-checkbox [id]="user.subGroupSpecifiedIdentifier+'claimDetailReportAccessIndicator'" [checked]="user.claimDetailReportAccessIndicator" disabled></rs-checkbox>
                            </ng-container>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                            <ng-container *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].satAccessLevel">
                                {{user.satAccessLevel || 'None'}}
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="!errors && userAndAdminAuths.relatedAuthorizations && userAndAdminAuths.relatedAuthorizations.length && !selectedUserId">
        <!-- <rs-pagination [(ngModel)]="currentPage" [totalItems]="totalAuths"
                        (pageChanged)="getMoreAuths($event)" [directionLinks]="true"
                        [itemsPerPage]="defaultPageSize" ></rs-pagination> -->
        <table>
            <thead>
                <th>Subgroup ID</th>
                <th>Subgroup Name</th>
                <th>Eligibility Access</th>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of userAndAdminAuths.relatedAuthorizations">
                    <tr *ngIf="user && user.subGroupSpecifiedIdentifier">
                        <td>{{user.subGroupSpecifiedIdentifier}}</td>
                        <td>{{user.subGroupName}}</td>
                        <td>
                            {{user.eligibilityAccessLevel || 'None'}}
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="!fetchingAuths && !errors && ((userAndAdminAuths.userRelatedAuthorizations && !userAndAdminAuths.userRelatedAuthorizations.length)|| (userAndAdminAuths.relatedAuthorizations && !userAndAdminAuths.relatedAuthorizations.length))">
        <p class="error">No authorizations were assigned {{(userAndAdminAuths.userRelatedAuthorizations && !userAndAdminAuths.userRelatedAuthorizations.length) ? 'for this user' : ''}} </p>
    </ng-container>
    <div class="pagination-container">
        <rs-paginator [length]="totalAuths"
                        (page)="getMoreAuths($event)" showFirstLastButtons
                        [pageSize]="defaultPageSize" ></rs-paginator>
    </div>
</div>
