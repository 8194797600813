
<rs-loader *ngIf="fetchingAuths || updatingAuths"></rs-loader>

<div class="auth-at-group-level">
    <p *ngIf="errors" class="error">{{errors}}</p>
    <!-- <p *ngIf="selectedOption === 'customizeClientSubgroups' && updatingAuthsResp && !updatingAuths" class="success-msg">
        Authorizations for the subgroups selected are saved, Proceed for next set or do whatever :)
    </p> -->
    <p *ngIf="updatingAutherrors" class="error">{{updatingAutherrors}}</p>
    <!-- Auth options at client or subgroup level -->
    <div class="auth-options">
        <ng-container *ngIf="displayConvertToUserCheck">
            <rs-checkbox color="secondary" id="convertToUser" name="convertToUser" label="Convert to user" value="convertToUser" (change)="displayConvertToUserCheck = false"></rs-checkbox>
        </ng-container>
        <rs-notification type="info" [visible]="selectedOption === 'clientAllSubgroups'" message="Please keep in mind that the access noted below for ‘All subgroups’ may not be available for every subgroup. For example, as the administrator, you may have access to Billing Reports for subgroup A, but not for subgroup B. In this case, selecting Billing Reports for all subgroups will not provide this user with Billing Reports for subgroup B.  Your users will not receive a higher level of access than you when selecting this option."></rs-notification>

        <ng-container *ngIf="!displayConvertToUserCheck">
            <rs-radio id="sub-radio-1" name="clientLevel" value="clientAllSubgroups" label="Select for all SubGroups" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
            <rs-radio id="sub-radio-2" name="clientLevel" value="customizeClientSubgroups" *ngIf="userInfo.adminRole !== 'BMT-SUBGROUP-ADMIN'" label="Select to customize SubGroups" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
            <rs-radio id="sub-radio-3" name="clientLevel" *ngIf="(userInfo.adminRole === 'BMT-GROUP-ADMIN' || userInfo.adminRole === 'BMT-SUBGROUP-ADMIN') && userInfo.selectedUserType.toLowerCase() !== 'delegate'" value="delegate" label="Create Delegate - a user who can create users and manage authorizations on your behalf" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
            <rs-radio id="sub-radio-4" name="clientLevel" value="revokeAtClient" label="Revoke authorizations for the group" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
        </ng-container>
    </div>
    <!-- Table to modify auths at allsubgroups or customize subgroups -->
    <div class="sub-group-auths" *ngIf="!fetchingAuths && selectedOption && selectedOption !== 'revokeAtClient' && selectedOption !== 'delegate' && !errors && userAndAdminAuths.userRelatedAuthorizations.length && editAllSubGroupsForm">
        <!-- <ng-container *ngIf="(!singleClientOrSubGroupAdmins.includes(userInfo.adminRole) && userAndAdminAuths.adminRelatedAuthorizations.subGroupAuthorizations && userAndAdminAuths.adminRelatedAuthorizations.subGroupAuthorizations.length && selectedOption === 'customizeClientSubgroups') || selectedOption === 'clientAllSubgroups'"> -->
            <form [formGroup]="editAllSubGroupsForm">
                <table>
                    <thead>
                        <th *ngIf="selectedOption === 'customizeClientSubgroups'">
                            <rs-checkbox id="selectAllSubGroups" checked="false" formControlName="selectAllSubGroups"></rs-checkbox>
                        </th>
                        <th>Subgroup ID</th>
                        <th>Subgroup Name</th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                            <span>Eligibility Access</span>
                            <rs-select id="adminEligAccess" formControlName="adminEligAccess" [items]="eligAccessLevelOptions"></rs-select>
                        </th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                            <span>Client Knowledge</span>
                            <rs-checkbox id="adminClientKnowledge" formControlName="adminClientKnowledge"></rs-checkbox>
                        </th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                          <span>Custom Client Reports</span>
                          <rs-checkbox id="adminCustomClientReport" formControlName="adminCustomClientReport"></rs-checkbox>
                        </th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                            <span>Billing Report</span>
                            <rs-checkbox id="adminBillingReport" formControlName="adminBillingReport"></rs-checkbox>
                        </th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                            <span>Claim Detail Report</span>
                            <rs-checkbox id="adminClientDetailReport" formControlName="adminClientDetailReport"></rs-checkbox>
                        </th>
                        <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                            <span>Sat Access Level</span>
                            <rs-select id="adminSatAccess" formControlName="adminSatAccess" [items]="satAccessLevelOptions"></rs-select>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of userAndAdminAuths.userRelatedAuthorizations;let i = index;">
                            <!-- Below is the form to manage auth for custom subgroups -->
                            <ng-container formArrayName="userAuthForm">
                                <ng-container *ngIf="selectedOption === 'customizeClientSubgroups'" [formGroupName]="i">
                                    <td>
                                        <rs-checkbox [id]="user.subGroupSpecifiedIdentifier" formControlName="userPrivilege"></rs-checkbox>
                                    </td>
                                    <td>{{user.subGroupSpecifiedIdentifier}}</td>
                                    <td>{{user.subGroupName}}</td>
                                    <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                                        <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].eligibilityAccessLevel && adminSubGroupAuths[user.subGroupSpecifiedIdentifier].eligibilityAccessLevel.toLowerCase() !== 'none'">
                                            <rs-select [id]="user.subGroupSpecifiedIdentifier+'userEligAccess'" formControlName="userEligAccess" [items]="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].eligibilityAccessLevel.toLowerCase() === 'inquiry' ? inquiryOptions : adminSubGroupAuths[user.subGroupSpecifiedIdentifier].eligibilityAccessLevel.toLowerCase() === 'None' ? noneOptions : updateOptions"></rs-select>
                                        </div>
                                    </td>
                                    <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                                        <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].clientKnowledgeIndicator">
                                            <rs-checkbox formControlName="userClientKnowledge" [id]="user.subGroupSpecifiedIdentifier+'userClientKnowledge'"></rs-checkbox>
                                        </div>
                                    </td>
                                  <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                                    <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].customClientReportAccessIndicator">
                                      <rs-checkbox formControlName="userCustomClientReportAccess" [id]="user.subGroupSpecifiedIdentifier+'userCustomClientReportAccess'"></rs-checkbox>
                                    </div>
                                  </td>
                                    <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                                        <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].billingReportAccessIndicator">
                                            <rs-checkbox formControlName="userBillingReport" [id]="user.subGroupSpecifiedIdentifier+'userBillingReport'"></rs-checkbox>
                                        </div>
                                    </td>
                                    <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                                        <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].claimDetailReportAccessIndicator">
                                            <rs-checkbox formControlName="userClientDetailReport" [id]="user.subGroupSpecifiedIdentifier+'userClientDetailReport'"></rs-checkbox>
                                        </div>
                                    </td>
                                    <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                                        <div *ngIf="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].satAccessLevel && adminSubGroupAuths[user.subGroupSpecifiedIdentifier].satAccessLevel.toLowerCase() !== 'none'">
                                            <rs-select formControlName="userSatAccess" [id]="user.subGroupSpecifiedIdentifier+'userSatAccess'" [items]="adminSubGroupAuths[user.subGroupSpecifiedIdentifier].satAccessLevel.toLowerCase() === 'inquiry' ? inquiryOptions : adminSubGroupAuths[user.subGroupSpecifiedIdentifier].satAccessLevel.toLowerCase() === 'None' ? noneOptions : updateOptions"></rs-select>
                                        </div>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!-- Below is the form to manage auth for all subgroups -->
                            <ng-container *ngIf="selectedOption === 'clientAllSubgroups'" formGroupName="userAuthForm">
                                <td>All</td>
                                <td>All SubGroups</td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                                    <rs-select formControlName="userEligAccess" id="userEligAccess" [items]="eligAccessLevelOptions"></rs-select>
                                </td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                                    <rs-checkbox formControlName="userClientKnowledge" id="userClientKnowledge"></rs-checkbox>
                                </td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                                  <rs-checkbox formControlName="userCustomClientReportAccess" id="userCustomClientReportAccess"></rs-checkbox>
                                </td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                                    <rs-checkbox formControlName="userBillingReport" id="userBillingReport"></rs-checkbox>
                                </td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                                    <rs-checkbox formControlName="userClientDetailReport" id="userClientDetailReport"></rs-checkbox>
                                </td>
                                <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                                    <rs-select formControlName="userSatAccess" id="userSatAccess" [items]="satAccessLevelOptions"></rs-select>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </form>
        <!-- </ng-container> -->
    </div>
    <rs-paginator *ngIf="selectedOption === 'customizeClientSubgroups' && !errors" [length]="totalAuths"
                    (page)="getMoreAuths($event)" showFirstLastButtons
                    [pageSize]="defaultPageSize"></rs-paginator>
    <!-- Buttons Contianer to save/revoke the auths -->
    <div class="btns-cont">
        <button rs-raised-button class="next-btn" [disabled]="!selectedOption || (selectedOption === 'customizeClientSubgroups' && !formChangesDetecedForCustomEdit) || (selectedOption === 'clientAllSubgroups' && !authsModifiedForAllSubGroups)" color="primary" (click)="manageAuthorizations()">Save</button>
        <button rs-raised-button color="secondary" (click)="cancelGroupAuthEdit.emit(true)">Cancel</button>
    </div>

</div>
<!-- Confirmation pop up -->
<rs-dialog [visible]="!!(selectedOption && updatingAuthsResp && !updatingAuths)" heading="User Authorizations" maxWidth="60%" class="bmt-manager-user-auth">
    <div style="padding: 20px;">
        <ng-container *ngIf="selectedOption !== 'customizeClientSubgroups'">
            <p>
                {{selectedOption === 'revokeAtClient' ? 'Authorizations for this group are revoked' : 'User authorizations are updated'}}
            </p>
            <div style="justify-content: space-between;display: flex;">
                <button style="width: auto;" rs-raised-button color="secondary" (click)="navigateToUserAdmin('viewAuth')">View authorizations</button>
                <button style="width: auto;" rs-raised-button color="secondary" (click)="navigateToUserAdmin('manage')">Manage other User</button>
            </div>
            <!-- <div style="justify-content: space-between;display: flex;" *ngIf="userInfo.adminRole !== 'BMT-GROUP-ADMIN' && userInfo.adminRole !== 'BMT-SUBGROUP-ADMIN'">
                <button style="width: auto;" rs-raised-button color="secondary" (click)="cancelGroupAuthEdit.emit(true)">View authorizations</button>
                <button style="width: auto;" rs-raised-button color="secondary" (click)="navigateToUserAdmin()">Go to users list</button>
            </div> -->
        </ng-container>
        <ng-container *ngIf="selectedOption === 'customizeClientSubgroups'">
            <p class="success-msg">
                Authorizations for the subgroups selected are saved{{totalAuths > 100 ? ', proceed for next set.': '.'}}
            </p>
            <!-- <p>
                This window will close automatically after 5secs or you can close it
            </p> -->
            <div style="justify-content: space-between;display: flex;">
                <button style="width: auto;" rs-raised-button color="secondary" (click)="navigateToUserAdmin('viewAuth')">View authorizations</button>
                <button style="width: auto;" rs-raised-button color="secondary" (click)="navigateToUserAdmin('close')">Close</button>
            </div>
        </ng-container>
    </div>
</rs-dialog>
