<div class="row pg-style manage-elig-container">
  <rs-loader *ngIf="isFecthingContractInfo || isFetchingUiAuth"></rs-loader>
  <div class="col-sm-12 pg-style">
    <!-- SEARCH BANNER -->
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Manage Eligibility</h2>
      </div>
    </div>
    <!-- SEARCH NAV -->
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
            <li *ngIf="manageEligFeatures.includes('member')"><a routerLink="/eligibility/member" (click)="setPageFlow('Member')"
                                                                 [class.search-nav-active]="subHeader === 'Member'">Member</a></li>
            <li *ngIf="manageEligFeatures.includes('addMember')"><a routerLink="/eligibility/add-member" (click)="setPageFlow('Add Member')"
                                                                    [class.search-nav-active]="subHeader === 'Add Member'">Add Member</a></li>
             <li *ngIf="manageEligFeatures.includes('enrollFamily')"><a routerLink="/eligibility/family-enrollment" (click)="setPageFlow('Family Enrollment')"
             [class.search-nav-active]="subHeader === 'Family Enrollment'">Family Enrollment</a></li>
        </ul>
      </div>
    </div>
    <div class="row search-comp">
      <div class="card col-sm-12">
        <!-- Member Search and Results-->
        <ng-container *ngIf="subHeader === 'Member'">
          <elig-search [key]="'BMT'" [selectedAdminPlan]="selectedAdminPlan"></elig-search>
          <elig-search-results [key]="'BMT'" [hideContentOnNoResults]="true" (resultSelected)="goToMemberDetails($event)"></elig-search-results>
        </ng-container>
        <!-- Showing Client Search and Results, when user choose other than member nav link -->
        <ng-container *ngIf="subHeader !== 'Member'">
          <bmt-client-search [selectedAdminPlan]="selectedAdminPlan" (clientSelected) = "clientSelected($event)" (subClientSelected)="subClientSelected($event)" (resetSelected) ="clientSearchReset($event)"></bmt-client-search>
        </ng-container>
        <!-- After User Selects Group and Sub Group-->
        <ng-container *ngIf="selectedSubClients && selectedSubClients.length > 0">
          <div class="row group-subgroup-heaidng">
            <label><b>Group:</b> <span><b>{{selectedSubClients[0].planAcronym}}</b> {{selectedSubClients[0].client.name}} {{selectedSubClients[0].client.specifiedId}}</span></label>
            <label><b>Subgroup:</b> <span>{{selectedSubClients[0].name}} {{selectedSubClients[0].specifiedId}}</span></label>
          </div>
          <!-- If user has choosed 'Add Member flow' -->
          <ng-container *ngIf="subHeader === 'Add Member'">
              <ng-template *ngIf="displayAddMemberFrom" [ngTemplateOutlet]="ssnForm"></ng-template>
              <p *ngIf="!isFetchingUiAuth && !displayAddMemberFrom" style="color: red;">You are not authorized to add a member.</p>
          </ng-container>
          <!-- If user has choosed 'Family Enrollment flow'  -->
          <ng-container *ngIf="(subHeader === 'Family Enrollment' && displayFamilyEnrollDefaultsForm)">
            <ng-container *ngIf="contractResposnse">
              <ng-template [ngTemplateOutlet]="familyEnrollmentDefaults"></ng-template>
              <ng-template *ngIf="showSsnFormForFamilyEnroll" [ngTemplateOutlet]="ssnForm"></ng-template>
            </ng-container>
            <p style="color: red;" *ngIf="!isFecthingContractInfo && !isFetchingUiAuth && !contractResposnse">Contract info is not available for the selected group and subgroup.</p>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Family Enrollment Detaults Form -->
  <ng-template #familyEnrollmentDefaults>
    <ng-container *ngIf="setDefaultsForm">
      <form [formGroup]="setDefaultsForm" (ngSubmit)="setDefaultsForFamilyEnroll()" class="family-enorllment-defaults-form">
          <div class="defaults-for-family-enroll">
            <div>
              <rs-input label="Received Date" formControlName="receivedDate" type="date"></rs-input>
              <rs-input label="Eligibility Effective Date" validation="eager" formControlName="eligibilityEffectiveDate" type="date"></rs-input>
              <rs-select label="Eligibility Status" validation="eager" [items]="[{label: 'Active', value: 'active'}]" formControlName="eligibilityStatus"></rs-select>
              <rs-select label="Eligibility Status Reason" validation="eager" [items]="eligStatusReasons" formControlName="eligibilityStatusReason"></rs-select>
            </div>
            <div>
              <rs-select *ngIf="!!qualifierTypes.length" validation="eager" label="Qualifier Type" [items]="qualifierTypes" formControlName="qualifierType"></rs-select>
              <rs-select *ngIf="!!memberBenefitTypes.length" validation="eager" label="Member Benefit Type" [items]="memberBenefitTypes" formControlName="memberBenefitType"></rs-select>
            </div>
          </div>
          <button [disabled]="!setDefaultsForm.valid || disableSetDefaults" rs-raised-button color="primary">SET DEFAULTS</button>
        </form>
    </ng-container>
  </ng-template>

  <!-- SSN form -->
  <ng-template #ssnForm>
    <div class="ssn-inputs">
      <h5>Specify Member</h5>
      <p *ngIf="showCopyError" style="color: chocolate;"><code>Ctrl + C</code> / Copy is Not Allowed</p>
      <ng-container *ngIf="addMemberForm">
        <form [formGroup]="addMemberForm" (ngSubmit)="navigateToAddMember()">
          <div>
            <rs-input label="Member ID" validation="eager" formControlName="ssn"
              (keydown.control.c)="onKeyPress($event)" [type]="changeType" [placeholder]="'No dashes or spaces'"></rs-input>
            <rs-input label="Confirm Member ID" validation="eager" formControlName="confirmSsn"
              (keydown.control.c)="onKeyPress($event)" type="password" [placeholder]="'No dashes or spaces'"></rs-input>
            <button [disabled]="!addMemberForm.valid" rs-raised-button color="primary">SUBMIT</button>
          </div>
        </form>
      </ng-container>
      <!-- Find member component for Elig Lib -->
      <elig-find-member [key]="'BMT'" *ngIf="(findMemberFeed | keyvalue)?.length" [feed]="findMemberFeed" (foundMember)="foundMember($event)"></elig-find-member>
    </div>
  </ng-template>
</div>

