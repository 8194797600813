<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Billing</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
          <li><a (click)="switchBillingTab('Invoices')" [class.search-nav-active]="activeTab === 'Invoices'">Invoices</a></li>
          <li><a (click)="switchBillingTab('My Reports')" [class.search-nav-active]="activeTab === 'My Reports'">My Reports</a></li>
          <li><a (click)="switchBillingTab('Historical Reports')" [class.search-nav-active]="activeTab === 'Historical Reports'">Historical Reports</a></li>
<!--          <li *ngIf="selectedAdminPlan !== 'DDMN'"><a (click)= "activeTab = 'EBill'; resetTab()" [class.search-nav-active]="activeTab === 'EBill'">EBill</a></li>-->
          <li *ngIf="billingAttributeFeatures.includes('paymentHistory') && selectedAdminPlan !== 'DDMN'"><a (click)="switchBillingTab('PaymentHistory')" [class.search-nav-active]="activeTab === 'PaymentHistory'">Payment History</a></li>
          <li><a (click)="switchBillingTab('Recurring ACH Setup')" [class.search-nav-active]="activeTab === 'Recurring ACH Setup'">Recurring ACH Setup</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row billing-search">
  <div class="col-sm-12 card">
    <div *ngIf="selectedAdminPlan && (activeTab === 'Invoices') || (activeTab === 'Historical Reports')" class="billing-component">
      <bmt-client-search [selectedAdminPlan]="selectedAdminPlan"  [allSubClientsSelectFeature] = true (clientSelected) = "clientSelected($event)"
      (subClientSelected)="subClientSelected($event)" (resetSelected)="resetSelected()" (selectedAllSubClients)="selectedAllSubClients($event)" ></bmt-client-search>
      <ng-container *ngIf="showReports">
        <billing  [billing] = "billingFeatures" [feed] ="billingFeed" [selectedAdminPlan]="selectedAdminPlan"  [userFeed]="userFeed"></billing>
      </ng-container>
    </div>
    <div *ngIf="activeTab === 'My Reports'" class="report-component">
      <billing-available-reports></billing-available-reports>
    </div>
    <div *ngIf="(activeTab === 'EBill') || (activeTab === 'Recurring ACH Setup') || (activeTab === 'PaymentHistory')" class="billing-component">
      <div class ="error" *ngIf="ebillError">{{ebillError}}</div>
      <bmt-client-search [selectedAdminPlan]="selectedAdminPlan" [allSubClientsSelectFeature] = "(activeTab === 'Recurring ACH Setup') || (activeTab === 'PaymentHistory')" (clientSelected) = "clientSelected($event)"
       (subClientSelected)="subClientSelected($event)" (resetSelected)="resetSelected()" (selectedAllSubClients)="selectedAllSubClients($event)"  [multiSelect]= "(activeTab === 'Recurring ACH Setup')"></bmt-client-search>
      <ng-container *ngIf="showClientSubclientInfo">
        <div class="row group-info">
          <div *ngIf="!allSubClients">
            <label><strong>Group:</strong> <span><strong>{{subGroupInfo[0].client.planAcronym}}</strong> {{subGroupInfo[0].client.name}}
                {{subGroupInfo[0].client.specifiedId}}</span></label>
            <label><strong>Subgroup:</strong>
              <span></span> <span *ngFor="let item of subGroupInfo; let isLast=last">{{item.specifiedId}} {{item.name}} <strong> {{isLast ? '' : ', '}}</strong></span></label>
          </div>
          <div *ngIf="allSubClients">
            <label><strong>Group:</strong> <span><strong>{{selectedClient.planAcronym}}</strong> {{selectedClient.name}}
                {{selectedClient.specifiedId}}</span></label>
            <label><strong>Subgroup:</strong>
              <span >'All Subgroups'</span></label>
          </div>
        </div>
        <div *ngIf="showPaymentHistory && activeTab === 'PaymentHistory'">
          <billing [billing]="['bmtPaymentHistory']" [feed] ="billingFeed" [selectedAdminPlan]="selectedAdminPlan"></billing>
        </div>
        <div *ngIf="activeTab === 'Recurring ACH Setup'">
          <ach-payment [isAllSubclientSelected] ="allSubClients" [groupDetails] = "(allSubClients ? selectedClient : subGroupInfo)" (resetSelected)="resetSelected()"></ach-payment>
        </div>
      </ng-container>
    </div>
  </div>
</div>
