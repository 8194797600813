<div class="family-enrollment-comtainer" *ngIf="familyEnrollDefaults && familyEnrollDefaults.memberId; else noDataFound;">
    <div class="sticky" [class.prd-sticky]="region === 'prod'">
      <div class="floating-banner">
        <div><label routerLink="/eligibility/family-enrollment">Manage Eligibility</label> / <label>Family
            Enrollment</label></div>
        <h4>Family Enrollment</h4>
      </div>
    </div>
    <div class="enroll-family-container" *ngIf="clientDetails && !displaySsnForm">
      <elig-enroll-family [key]="'BMT'" (wasEnrollmentSuccess)="wasEnrollmentSuccess($event)"
        (cancelFamilyEnrollment)="cancelFamilyEnrollment()" [familyEnrollFeatures]="familyEnrollFeatures"
        [selectedAdminPlan]="selectedAdminPlan" [familyEnrollDefaults]="familyEnrollDefaults"
        [clientDetails]="clientDetails"></elig-enroll-family>
    </div>

    <!-- SSN form -->
    <rs-dialog [visible]="displaySsnForm" (visibleChange)="displaySsnForm = false;cancelFamilyEnrollment();" heading="Enter Member Id" maxWidth="768px">
      <ng-container *ngIf="setDefaultsForm">
        <form [formGroup]="setDefaultsForm" (ngSubmit)="setDefaultsForFamilyEnroll()"
          class="family-enorllment-defaults-form">
          <div class="defaults-for-family-enroll">
            <div>
              <rs-input label="Received Date" formControlName="receivedDate" type="date"></rs-input>
              <rs-input label="Eligibility Effective Date" validation="eager" formControlName="eligibilityEffectiveDate"
                type="date"></rs-input>
              <rs-select label="Eligibility Status" validation="eager" [items]="[{label: 'Active', value: 'active'}]"
                formControlName="eligibilityStatus"></rs-select>
              <rs-select label="Eligibility Status Reason" validation="eager" [items]="eligStatusReasons"
                formControlName="eligibilityStatusReason"></rs-select>
            </div>
            <div>
              <rs-select *ngIf="!!qualifierTypes.length" validation="eager" label="Qualifier Type"
                [items]="qualifierTypes" formControlName="qualifierType"></rs-select>
              <rs-select *ngIf="!!memberBenefitTypes.length" validation="eager" label="Member Benefit Type"
                [items]="memberBenefitTypes" formControlName="memberBenefitType"></rs-select>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="ssn-inputs">
        <p *ngIf="showCopyError" style="color: chocolate;padding: 20px 20px 0px 20px;"><code>Ctrl + C</code> / Copy is Not
          Allowed</p>
        <ng-container *ngIf="addMemberForm">
          <form [formGroup]="addMemberForm" (ngSubmit)="triggerFindMember()">
            <div class="form-and-buttons">
              <rs-input label="Member ID" validation="eager" formControlName="ssn" (keydown.control.c)="onKeyPress($event)"
                [type]="changeType" [placeholder]="'No dashes or spaces'"></rs-input>
              <rs-input label="Confirm Member ID" validation="eager" formControlName="confirmSsn"
                (keydown.control.c)="onKeyPress($event)" type="password" [placeholder]="'No dashes or spaces'"></rs-input>
              <button [disabled]="!addMemberForm.valid" rs-raised-button color="primary">SUBMIT</button>
            </div>
          </form>
        </ng-container>
      </div>
    </rs-dialog>
    <!-- Find member component for Elig Lib -->
    <elig-find-member [key]="'BMT'" *ngIf="(findMemberFeed | keyvalue)?.length" [feed]="findMemberFeed"
      (foundMember)="foundMember($event)"></elig-find-member>
</div>
<ng-template #noDataFound>
    <p style="color: red;padding: 50px 50px 0;">
        No member id or defaults found to enroll a family
    </p>
</ng-template>
