import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/shared/app.config';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GoogleAnalyticsService {
  googleTag: string;

  constructor(@Inject(DOCUMENT) private doc: Document, private router: Router, private env: AppConfig) {
    this.googleTag = this.env.googleTagManager[this.doc.location.pathname.split('/')[1]];
  }

  googleAnalyticsG4() {
    // Create the first script element for Google Tag Manager
    const script1 = this.doc.createElement('script');
    script1.async = true;
    const scriptContent = `
    window.dataLayer = window.dataLayer || [];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })
      (window,document,'script','dataLayer','${this.googleTag}')`;
    script1.appendChild(document.createTextNode(scriptContent));
    this.doc.head.appendChild(script1);

    // Create the noscript element for Google Tag Manager
    const script2 = this.doc.createElement('noscript');
    const iframe = this.doc.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.googleTag}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    script2.appendChild(iframe);
    this.doc.body.appendChild(script2);
  }

}
