<section style="height: 100%">
  <div style="height: 100%;" class="row-flex header-block header-bg">
    <header class="col registration-1">
      <div class="logo-col">
        <a class="logo-delta-white-registration" [class.logo-ren-white-registration]="planFromUrl === 'ren'"><span class="sr-only">logo</span></a>
        <h1 class="h3">Benefit Manager Toolkit &reg;</h1>
      </div>
    </header>
    <rs-loader *ngIf="isValidatingInfo"></rs-loader>
    <main class="col registration-2">
      <div class="registration-frame" *ngIf="!confirmation">
        <div class="alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
        <app-register *ngIf="!credentials" (registerSubmit)="registerSubmit($event)"></app-register>
        <app-credentials *ngIf="credentials" (credentialsSubmit)="credentialsSubmit($event)"></app-credentials>
      </div>
      <app-confirmation *ngIf="confirmation" [userInfo]="userInfo"></app-confirmation>
    </main>
  </div>
</section>
