import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../shared/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {

  constructor(private http: HttpClient, private config: AppConfig) {
  }

  getUserProfile() {
    return this.http.get<any>(this.config.userProfileUrl);
  }

  getUserResources() {
    return this.http.get<any>(this.config.userResourcesUrl);
  }

  getAuthorizations(authMode, context): any {
    const url = this.config.uiAuthorizationsUrl + '?type=' + (authMode === 'relaxed' ? 'R' : 'S') + '&' + 'context=' + context;
    return this.http.get(url);
  }
}
