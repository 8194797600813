import { IReportsState } from './state';

export const initialReportsState: IReportsState = {
  massReportError: '',
  reportType: '',
  massActionsReport: {
    completedReports: [
      {
        sourceClientSpecifiedIdentifier: '',
        sourceSubclientSpecifiedIdentifier: '',
        type: '',
        createddate: '',
        expirationdate: '',
        report: ''
      }
    ],
    inProcess: [
      {
        type: '',
        status: '',
        requestDate: ''
      }
    ]
  },
  // Overage Dependent Notice
  overageDepNoticeReq: {
    pagination: {
      offset: 0,
      limit: 100
    },
    searchCriteria: {
      planAcronym: '',
      clientSpecifiedId: '',
      subclientSpecifiedId: '',
    }
  },
  overageDepNoticeRes: {
    pagination: {
      limit: 100,
      offset: 0
    },
    notices: [{
      clientSpecifiedId: '',
      dateGenerated: '',
      deliveryType: '',
      format: '',
      id: '',
      planAcronym: '',
      subclientSpecifiedId: '',
    }],
  },
  overageDepNoticeError: '',
  isFetchingOverageDepNotice: false,
  isFetchingDocument: false,
  isFetchingConsolidatedReport: false,
  consolidatedReportErrors: '',
  // Ad-hoc report
  adhocReportReq: {
    planAcronym: '',
    clientSpecifiedId: '',
    subclientSpecifiedId: '',
    asOf: '',
    ageRange: {
      start: 0,
      end: 0
    }
  },
  adhocReportError: '',
  isFetchingAdhocReport: false,
  customGroupReportRes: {
    reports: [{
      id: '',
      name: '',
      format: '',
      deliveryType: '',
      frequency: '',
      date: '',
      startDate: '',
      endDate: '',
      clientSubClientId: [{
        clientSpecifiedId: '',
        subClientSpecifiedIds: ''
      }]
    }],
    pagination: {
      limit: 25,
      offset: 0
    },
    sort: [{
      order: '',
      fieldName: ''
    }]
  },
  // Custom Group Reports State
  customGroupReportReq: {
    planAcronym: '',
    clientSpecifiedId: '',
    subClientSpecifiedId: '',
    fromDate: '',
    toDate: '',
    frequency: '',
    pagination: {
      limit: 25,
      offset: 0
    },
    sort: [{
      order: '',
      fieldName: ''
    }]
  },
  customGroupReportError: '',
  isFetchingCustomGroupReport: false,
  errorFetchingDocument: ''
};
