<div id="scrollId"></div>
<div class="view-auth-container">
    <a (click)="navigateToUserAdmin()" class="back-to-users-link" *ngIf="!editAuthorizations"><< Back to Users List</a>
    <h4 class="view-or-edit-auth-heading">{{!editAuthorizations ? 'Viewing ':'Managing '}}Authorizations</h4>
    <div class="userId">
        <div>
            <span>Selected User:</span> <label>{{userInfo.selectedUserId}}</label>
            <span style="padding-left: 15px;">User type:</span> <label>{{userInfo.selectedUserType}}</label>
        </div>
        <ng-container *ngIf="!multipleClients">
            <div class="user-context">
            <!-- <span>Admin Payer:</span><label>{{userContext[0]}}</label> -->
                <span>Payer:</span><label>{{userContext[1]}}</label>
                <span>Group:</span><label>{{userContext[2]}}</label>
                <!-- <span *ngIf="userInfo.adminRole === 'BMT-SUBGROUP-ADMIN'">Sub-Group:</span><label>{{userContext[3]}}</label> -->
            </div>
        </ng-container>
        <button *ngIf="!editAuthorizations" rs-raised-button color="primary" (click)="editAuthorizations = true">Edit</button>
    </div>
    <ng-container *ngIf="!editAuthorizations">
        <rs-loader *ngIf="fetchingClients"></rs-loader>
        <ng-container *ngIf="!multipleClients">
            <bmt-view-auth-at-group [userInfo]="userInfo" [userContext]="userInfo.contextOfAdmin" [selectedUserId]="userInfo.selectedUserId"></bmt-view-auth-at-group>
        </ng-container>
        <ng-container *ngIf="multipleClients">
            <div class="admin-clients-container">
                <p *ngIf="errorsFetchingClients" class="error">Errors fetching clients: {{errorsFetchingClients}}</p>
                <ng-container *ngFor="let clientsInfo of adminClientsInfo;let i = index;">
                    <ng-container *ngIf="clientsInfo.specifiedId">
                        <div class="admin-clients">
                            <div class="user-context" (click)="toggleTheClient(clientsInfo.specifiedId+'index'+i)">
                                <div class="arrows">
                                    <span *ngIf="selectedClient !== clientsInfo.specifiedId+'index'+i">&#43;</span>
                                    <span *ngIf="selectedClient === clientsInfo.specifiedId+'index'+i">&#8722;</span>
                                </div>
                                <div class="context">
                                    <!-- <span>Admin Payer:</span>{{clientsInfo.ADMINPLAN}} -->
                                    <span>Payer:</span>{{clientsInfo.planAcronym}}
                                    <span>Group Name:</span>{{clientsInfo.name}}
                                    <span>Group Id:</span>{{clientsInfo.specifiedId}}
                                </div>
                            </div>
                            <ng-container *ngIf="selectedClient === clientsInfo.specifiedId+'index'+i">
                                <bmt-view-auth-at-group [userInfo]="userInfo" [userContext]="userContext[0]+'/'+clientsInfo.planAcronym+'/'+clientsInfo.specifiedId" [selectedUserId]="userInfo.selectedUserId"></bmt-view-auth-at-group>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="pagination-container">
                <rs-paginator [length]="totalRecords"
                        (page)="getMoreClients($event)" showFirstLastButtons
                        [pageSize]="defaultPageSize"></rs-paginator>
            </div>
        </ng-container>
    </ng-container>
    <bmt-manage-auth *ngIf="editAuthorizations" [userInfo]="userInfo" (cancelEdit)="editAuthorizations = false"></bmt-manage-auth>
</div>
