<div class="row pg-style">
  <div class="col-sm-12 pg-style">
    <div class="row search-banner">
      <div class="col-sm-12 search-banner-col">
        <h2 class="h3 search-banner-title">Help</h2>
      </div>
    </div>
    <div class="row search-nav">
      <div class="col-sm-12 search-nav-col">
        <ul class="list-inline search-nav-list">
        </ul>
      </div>
    </div>
    <div class="row search-comp">
      <div class="card col-sm-12">
        <div class="help-component" [innerHTML]="helpContent"></div>
      </div>
    </div>
  </div>
</div>
