// tslint:disable: no-unused-expression
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PreviousRouteService } from '../shared/services/previousUrl.service';
import { AppConfig } from '../shared/app.config';

@Component({
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {
  region = this.config.region;
  planDetailsFeed: any;
  clientSelected: any;
  selectedAdminPlan = '';
  clientDetails: any;

  addMemberFeed: { clientId: any; subClientId: any; planAcronym: number; memberId: any; subscriberEnrollmentID: any; asOf: string };
  @Output() setMemberStore: EventEmitter<any> = new EventEmitter();

  constructor(private route: Router,
              private location: Location,
              private routerService: PreviousRouteService,
              private config: AppConfig) {
  }

  ngOnInit() {
    this.setMemberStore.emit({ selectedDependent: {}, dependentAdded: false });
  }

  wasAddDone(action) {
    if (typeof action !== 'string') {
      action.subscriber && this.setMemberStore.emit({ selectedSubscriber: {
        firstName: action.subscriber.name.first,
        lastName: action.subscriber.name.last
      }});
      action.dependent && this.setMemberStore.emit({ selectedDependent: action.dependent, dependentAdded: true});
      this.route.navigate(['details']);
    }
    if (action === 'cancelled') {
      if (this.routerService.getPreviousUrl().indexOf('eligibility') > -1) {
        this.route.navigate(['eligibility/add-member']);
      } else {
        this.location.back();
      }
    }
  }

}
