<div class="register-component">
  <div class="error-register" *ngIf="error">
    <p class="error">{{error}}</p>
    <p>If you already have an account <a routerLink="/login">login</a> here</p>
  </div>
  <form *ngIf="!error" [formGroup]="registerForm" #ngRegisterForm>
    <div class="strong">Register Account <span>(Step 1 of 2)</span></div>
    <rs-input class="form-content" formControlName='authorizationCode' label="Authorization Code" validation="eager" maxlength="25" type="text"></rs-input>
    <rs-input class="form-content" formControlName='emailAddress' label="Email Address" validation="eager" type="text"></rs-input>
    <rs-input *ngIf="!(agencyLevel || independentAgent)" class="form-content" formControlName='groupSpecifiedIdentifier' label="Group ID" validation="eager" maxlength="25" type="text"></rs-input>
    <rs-input *ngIf="subgroupLevel" class="form-content" formControlName='subGroupSpecifiedIdentifier' label="Subgroup ID" validation="eager" maxlength="25" type="text"></rs-input>
    <rs-input *ngIf="agencyLevel" class="form-content" formControlName='agencyTin' label="Agency TIN" validation="eager" maxlength="9" type="text"></rs-input>
    <rs-input *ngIf="independentAgent" class="form-content" formControlName='agentSsn' label="Agent SSN" validation="eager" maxlength="9" type="text"></rs-input>
    <div class="register-buttons">
      <button rs-raised-button color="primary" type="submit" (click)="registerSubmit.emit(registerForm.value)" [disabled]="registerForm.invalid">Continue</button>
    </div>
  </form>
</div>
