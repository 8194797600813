export const appUiAuthInitialState: IAppAuthorizations = {
  relaxedMode: {},
  strictMode: {},
  appContext: '',
  isFetchingUiAuth: false
};

export interface IAppAuthorizations {
  relaxedMode: IUiAuthorizations | {};
  strictMode: IUiAuthorizations | {};
  appContext: string;
  isFetchingUiAuth: boolean;
}

export interface IUiAuthorizations {
  reports?: string [];
  leftnav?: string [];
  manageeligibility?: string [];
  eligibility?: {
    addMember: string [];
    search: string [];
    memberInfo: string [];
    searchresults: string [];
    coverageInfo: string [];
    familyEnrollment: string [];
  };
  client?: {
    search: string [];
    planDetails: string [];
    searchresults: string[];
  };
  billing?: string [];
  benefitsInquiry?: {
    benefits: string[];
    coinsuranceFeatures: string[];
    additionalInfoFeatures: string[];
    accumulatorsFeatures: string[];
    orthodonticFeatures: string[];
    clientPlanDetails: string[];
  };
}
