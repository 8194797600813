import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/shared/app.config';
import { IAuthInfo } from './state';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAuthInfo() {
    return this.http.get<IAuthInfo>(this.config.authInfoUrl, {
      observe: 'response',
    });
  }

  getSessionId() {
    return this.http.get(this.config.sessionIdUrl, { responseType: 'text' });
  }

  getUserProfile() {
    return this.http.get<any>(this.config.userProfileUrl, {
      observe: 'response',
    });
  }

  getUserResources() {
    return this.http.get<any>(this.config.userResourcesUrl, {
      observe: 'response',
    });
  }
}
